li::before {
    content: "";
    position: absolute;
    left: -30px;
    display: block;
    width: 20px;
    height: 20px;
    background: url('../../images//check.svg') no-repeat;
    background-size: contain;
}

.h3-stl-warranty {
    color: #000000;
    text-align: center;
    font-family: "640-font", sans-serif;
    font-size: 35px;
    text-transform: uppercase;
    letter-spacing: 4px;
}
.h5-stl-warranty{
    color: #000000;
    font-size: 16px;
    font-family: "640-font", sans-serif;
}

.h5-stl-footer{
    color: #e5e5e5;
    font-size: 20px;
    font-family: "640-font", sans-serif;
}
@media (max-width: 1200px) {

    li::before {

        left: -40px;
       
    }
}
