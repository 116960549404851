.button-pos-r{
    text-align: right;
}
.button-pos-l{
    text-align: left;
}
.dzu-previewStatusContainer{
    width: 100% !important;
}
.dzu-previewImage{
    max-width: 100px;
    max-height: 100px;
}
progress{
    width: 0%;
}
.dzu-previewButton{
    position: absolute;
    top:10px;
    left: 60px;
    background-image: url ('../../../images/RemoveImage.svg') ;
}
.dzu-inputLabel{
    font-family: "640-font",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #838181;
}

.dzu-inputLabel2{
    font-family: "640-font",sans-serif;
    font-style: normal;
    font-weight: bold;
    color:#fff;
    font-size: 14px;
    line-height: 18px;
    color: #838181;
}
@media (max-width: 450px) {
    .button-pos-r{
        text-align: center;
    }
    .button-pos-l{
        text-align: center;
    }
}