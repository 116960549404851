
.historic-details .record:before {
    background-color: #CC2229;
    width: 2px;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5px;
    z-index: 1;
}

.historic-details .record:after {
    z-index: 10;
    content: '';
    position: absolute;
    top: 0;
    left: -2px;
    display: block;
    width: 16px;
    height: 16px;
    background: url(../../images//CircleRed.svg) no-repeat;
    background-size: cover;
}