.h3-stl-footer{
        color: #FFF;
        text-transform: uppercase;
        font-size: 30px;
        font-family: "640-font", sans-serif;
        border-bottom: 4px solid #9b9b9b;
        padding-bottom: 20px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin-bottom: 20px;
}
a{
    line-height: 1.2em;
    font-family: "640-font", sans-serif;
    font-size: 18px;
    margin: 0;
    color: #9b9b9b;
    text-decoration: none;
}
a:hover{
    color: #FFF;
}

.h3-stl-footer-btn{
    color: #FFF;
    text-transform: uppercase;
    font-size: 25px;
    font-family: "640-font", sans-serif;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

}
.h4-stl-footer-btn{
    color: #FFF;
    text-transform: uppercase;
    font-size: 20px;
    font-family: "640-font", sans-serif;

    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

}



@media (max-width: 1200px) {

    .h3-stl-footer-btn{
        font-size: 12px;
    }
    .h4-stl-footer-btn{
        font-size: 14px;
    }

    .h3-stl-footer{
        font-size: 13px;   
    }
}