
.btn-gray:hover:not(:disabled), .see-all:hover:not(:disabled) {
    color: white;
    text-align: left;
    padding: 15px;
    text-decoration: none;
    background-color: #e60003;
    border: 2px solid #e60003;
    color: white !important;
    display: flex;
    float: left;
}

.btn-gray, .see-all {
    color: white;
    padding: 15px;
    display: block;
    margin: 0 auto;
    text-transform: uppercase;
    text-align: left;
    color: #FFF;
    background-color: #505050;;

    border: 2px solid #505050;
    float: right;
    display: flex;
    float: left;
}