
.arrow{
    position: absolute;
    content: " ";
    background: url('../../../images/RedArrow.svg');
    width: 34px;
    height: 17px;
    display: inline-block;
    left: 30%;
}

.arrow2{
    position: absolute;
    content: " ";
    background: url('../../../images/RedArrow.svg');
    width: 34px;
    height: 17px;
    display: inline-block;
    left: 43.5%;

}
.arrow3{
    position: absolute;
    content: " ";
    background: url('../../../images/RedArrow.svg');
    width: 34px;
    height: 17px;
    display: inline-block;
    left: 55%;
}
.arrow4{
    position: absolute;
    content: " ";
    background: url('../../../images/RedArrow.svg');
    width: 34px;
    height: 17px;
    display: inline-block;
    left: 68.5%;
}


.gray-back  {
    background: #5a5a5a;
}

.red-back{
    background: #e60003;
}

.textTabsred{

    color: #fff;
    text-align: center;
    font-family: "640-font",sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    padding: 15px 5%;
    background: #e60003;
    margin: 0;
}

.textTabs{

    color: #fff;
    text-align: center;
    font-family: "640-font",sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    padding: 15px 5%;
    background: #5a5a5a;
    margin: 0;
}