.button-font{
    color: white;
    font-size: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.textbutton{
    padding-left: 100px;
}
.button-font:hover{
    color: #e60003;
    font-size: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.btn-red:hover:not(:disabled), .see-all:hover:not(:disabled) {
    color: white;
    font-size: 12px;
    font-weight: 700px;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none;
    background-color: transparent;
    border: 2px solid #e60003;
    color: #e60003 !important;
    display: flex;
}

.textMainButton{
    margin-left: 10px;
    text-align: right !important;
    color: #000;
}

.textMainButton:hover{
    margin-left: 10px;
    color: #e60003;
}


.btn-red, .see-all {
    color: white;
    font-size: 12px;
    padding-left: 10px;
    font-weight: 700px;
    padding-right: 10px;
    display: block;
    margin: 0 auto;
    text-transform: uppercase;
    text-align: left;
    color: #FFF;
    background-color: #e60003;
    font-family: "DINPro-CondensedBold", sans-serif;
    padding: 0px 10px;
    border: 2px solid #e60003;
    float: right;
    display: flex;
}


.btn-white{
    color: #000;
    font-size: 12px;
    padding-left: 10px;
    font-weight: 700px;
    padding-right: 10px;
    display: block;
    margin: 0 auto;
    text-transform: uppercase;
    text-align: center;
    background-color: #FFF;
    font-family: "DINPro-CondensedBold", sans-serif;
    padding: 0px 10px 10px 10px;
    border: 2px solid #FFF;
    float: right;
    display: flex;
    justify-content:center!important;
}

.btn-white:hover:not(:disabled){
    font-size: 12px;
    font-weight: 700px;

    padding: 0px 10px 10px 10px;
    text-decoration: none;
    background-color: transparent;
    border: 2px solid #e60003;
    color: #e60003 !important;
    display: flex;
    justify-content:center !important;
}
