.historic-list{
    border-top: 3px solid #e60003 !important;
    margin-top: 0px;
    margin-bottom: 30px;
    margin-left: 0;
    position: relative;
    width: 50%;
    z-index: 100;
}
.h2-stl-Track{
    color: #000000;
    text-align: left;
    font-family:  "640-font",sans-serif;
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: 1px;
}