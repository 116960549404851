.button-font{
    color: white;
    font-size: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.textbutton{
    padding-left: 100px;
}
.button-font:hover{
    color: #e60003;
    font-size: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.btn-red:hover:not(:disabled), .see-all:hover:not(:disabled) {
    color: white;
    font-size: 14px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none;
    background-color: transparent;
    border: 2px solid #e60003;
    color: #e60003 !important;
    display: flex;
}


.textMainButton{
    margin-left: 10px;
    color: #000;
    display:flex;
    justify-content: right !important;

}

.textMainButton:hover{
    margin-left: 10px;
    color: #e60003;
}


.btn-red, .see-all {
    color: white;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
    margin: 0 auto;
    text-transform: uppercase;
    text-align: center;
    color: #FFF;
    background-color: #e60003;
    font-family: "DINPro-CondensedBold", sans-serif;
    padding: 0px 10px;
    border: 2px solid #e60003;
    float: right;
    display: flex;
}



.btn-red:disabled, .see-all:disabled {
    color: white;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: no-drop;
    display: block;
    margin: 0 auto;
    text-transform: uppercase;
    text-align: center;
    color: #000;
    background-color: rgb(211, 211, 211);
    font-family: "DINPro-CondensedBold", sans-serif;
    padding: 0px 10px;
    border: 2px solid rgb(211, 211, 211);
    float: right;
    display: flex;
}