.button-font{
    color: white;
    font-size: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.textbutton{
    padding-left: 100px;
}
.button-font:hover{
    color: #e60003;
    font-size: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.btn-red-cl:hover:not(:disabled){
    color: white;
    font-size: 12px;
    font-weight: 700px;
    text-align: left;
    padding: 0px 10px 5px 10px;
    text-decoration: none;
    background-color: transparent;
    border: 2px solid #e60003;
    color: #e60003 !important;
    display: flex;
    justify-content:center!important;
}

.textMainButton{
    margin-left: 10px;
    text-align: right !important;
    color: #000;
}

.textMainButton:hover{
    margin-left: 10px;
    color: #e60003;
}


.btn-red-cl{
    color: white;
    font-size: 12px;
    font-weight: 700px;
    display: block;
    margin: 0 auto;
    text-transform: uppercase;
    text-align: left;
    color: #FFF;
    background-color: #e60003;
    font-family: "DINPro-CondensedBold", sans-serif;
    padding: 0px 10px 5px 10px;
    border: 2px solid #e60003;
    /* float: right; */
    display: flex;
    justify-content:center!important;
}




.btn-red-cl:disabled, .see-all:disabled {
    color: white;
    font-weight: 700px;
    padding-bottom: 7px !important;
    font-size: 12px;
    cursor: no-drop;
    display: block;
    margin: 0 auto;
    text-transform: uppercase;
    text-align: left;
    color: #000;
    background-color: rgb(211, 211, 211);
    font-family: "DINPro-CondensedBold", sans-serif;
    padding: 0px 10px;
    border: 2px solid rgb(211, 211, 211);
    display: flex;
    justify-content:center!important;
}