*{
    margin: 0;
    padding: 0;
    /* background-color: rgb(28, 30, 31); */
    box-sizing: border-box;
    max-width: 100vw;
}

.h1-stl{
    color: #fff;
    font-size: 50px;
    font-family: "640-font",sans-serif;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 0 !important;
    line-height: 45px;
}

.h2-stl{
    color: #000000;
    text-align: center;
    font-family:  "640-font",sans-serif;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.h3-stl{
    color: white;
    text-align: center;
    font-family:  "640-font",sans-serif;
    font-size: 35px;
    text-transform: uppercase;
}

.h4-stl{
    font-family:  "640-font", sans-serif;
    font-size: 15px;
    color: #000000;
}

.h5-stl{
    font-size: 18px;
    font-family: "640-font",sans-serif;
    color: white;
    margin-left: -30px;
    
}

.h5-stl-main{
    font-size: 18px;
    font-family: "640-font",sans-serif;
    color: white;    
}
.h6-return{
    position: absolute;
    top: 100px;
    left: 5%;
    color: white;
    font-size: 16px;
    font-family:  "640-font" sans-serif;
}
.h6-final{
 
    color: #000000;
    font-size: 10px;
    font-family:  "640-font" sans-serif;
}

.h6-stl{
    font-size: 15px;
    color: #000000;
    font-family:  "640-font" sans-serif;
  
}

.linkTerms{
    font-size: 15px;
    color: #000000;
    font-family:  "640-font" sans-serif;
    text-decoration: underline;
    text-align: justify !important;

}
.alet-warning{
    color: #897256;
    background: #efd9ba;
    border-color: #edc793;
}
.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;}
    
.linkTerms:hover{
    text-decoration: none;
    color: #000000;
    text-align: justify;
}
.Background_Gray{
    background-color: rgb(28, 30, 31);
}
.Background_Black{
    background-color:#000000;
}
.Background_Gray2{
    background-color: rgb(38, 38, 38);
}

.Background_White{
    background-color: #FFFFFF;
}
.Center{
    text-align: center;
}

.Left{
    text-align:left;
}
.Rigth{
    text-align: right;
}

.Rigth-l{
    text-align: right;
}

.marginMain{
    margin-right: 1em !important;
    
}

.mainHeader{
    background-color:#000000;
    margin: 0%;
    max-width: 100%;
}
.logoMainHeader{
    padding-left: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
}

.advantages::before {
    content: "";
    position: absolute;
    display: block;
    left: 40%;
    width: 20px;
    height: 20px;
    background: url(../images/check.svg) no-repeat;
    background-size: contain;
}

.center__text{
    display: flex;
    justify-content: center;
}

.container_row{
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);
}
.font__maxwidth{
    max-width: 300px;
}
@media (max-width: 1200px) {

    .advantages::before {
        left: 37%;
    }
    .logoMainHeader{
       
        width: 100%;
    }
    .row {
        --bs-gutter-x: 0;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(var(--bs-gutter-y) * -1);
        margin-right: calc(var(--bs-gutter-x) * -.5);
        margin-left: calc(var(--bs-gutter-x) * -.5);
    }
    .mar_responsive{
        padding: 0 10px !important;
    }
}

@media (max-width: 820px) {
    .logoMainHeader{
        width: 20%;
    }
    .marginMain{
        margin-left: 0em !important;
        margin-right: 0 !important;
        width: 50%;
    }
}
@media (max-width: 450px) {
    .advantages::before {
        left: 2%;
    }

    .Rigth-l{
        text-align: left;
    }

    .h1-stl{
        color: #fff;
        font-size: 20px;
        font-family: "640-font",sans-serif;
        letter-spacing: 4px;
        text-transform: uppercase;
        text-align: center;
        margin-top: 0 !important;
        line-height: 45px;
    }
    .h2-stl{
        color: #000000;
        text-align: center;
        font-family:  "640-font",sans-serif;
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .h3-stl{
        color: white;
        text-align: center;
        font-family:  "640-font",sans-serif;
        font-size: 20px;
        text-transform: uppercase;
    }

    .marginMain{
        margin-left: 3em !important;
        margin-right: 0;
        width: 80%;
    }
    .logoMainHeader{
        width: 100%;
    }
}
